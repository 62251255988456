.Numbers-Box-Container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
}

.Numbers-Box-Container .Number-box-item {
  width: 220px;
  height: 180px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: #4bf1b1 10px 10px 10px -10px, #4bf1b1 -10px 0 10px -10px;
  background: #fff;
}

.Numbers-Box-Container .Number-box-item h3 {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: #7f1dff;
}

.Number-box-item p {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Number-box-item img {
  width: 24px;
  height: 24px;
  margin: auto 5px;
}

@media screen and (max-width: 768px) {
  .Numbers-Box-Container {
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: calc(160px * 2);
    height: calc(128px * 2);
  }
  .Numbers-Box-Container .Number-box-item {
    width: calc(280px / 2);
    height: 118px;
    align-items: center;
    justify-content: center;
  }

  .Numbers-Box-Container .Number-box-item h3 {
    font-size: 24px;
    line-height: 36px;
  }

  .Numbers-Box-Container .Number-box-item p {
    font-size: 12px;
    line-height: 36px;
    text-align: center;
    width: 147px;
    flex-direction: column;
  }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  .Numbers-Box-Container {
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
  }
  .Numbers-Box-Container .Number-box-item {
    width: 230px;
    height: 158px;
    align-items: center;
    justify-content: center;
  }

  .Numbers-Box-Container .Number-box-item h3 {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
    padding: 10px;
  }

  .Numbers-Box-Container .Number-box-item p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
}
