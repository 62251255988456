.Main-Logo-css {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
}

.Main-Logo-css img {
  width: 48px;
}
.Main-Logo-css h1 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #fff;
  padding-left: 10px;
  text-transform: uppercase;
}
.Main-Logo-css strong:nth-child(1) {
  color: #ff8300;
}
@media screen and (max-width: 700px) {
  .Main-Logo-css {
    width: 90%;
    margin-left: -2%;
  }
  .Main-Logo-css h1 {
    font-size: 90%;
    padding: 2px;
  }
  .Main-Logo-css img {
    width: 20%;
  }
}
