.CaseStudies-extra-contain {
  margin: 0 auto;

  background: #000d12;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  color: #fff;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.top-part {
  position: relative;
  padding: 40px;
  /* margin-left: 10%; */
  margin: auto;
  max-width: 90%;
}
.top-part p {
  font-size: 26px;
  line-height: 48px;
}
.MainHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 55%;
  max-height: 10%;
  font-size: 96px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-weight: 800;
}
.MainHeading img,
.CaseStudyMainLogo img {
  max-width: 25%;
  width: 100%;
}
.MainHeading .CaseStudyMainLogo h1 {
  font-size: 60%;
}
.csbtn {
  background: rgba(255, 255, 255, 1);
  color: #000;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 32%;
  right: 6%;
  text-align: center;
  border-radius: 50px;
  font-size: 40px;
  line-height: 50px;
}
.csbtn:hover {
  color: #00000080;
  background-color: #9747ff;
  cursor: pointer;
}

.Video-Section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Video-Section .right-col {
  flex: 0.5 50%;
  position: relative;
}
.Video-Section .right-col > span {
  position: absolute;
  left: 85%;
  top: -22%;
  cursor: pointer;
}
.Video-Section .right-col > span img {
  position: relative;
  z-index: 1;
}
.Video-Section .right-col > span .play-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -35%);
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
  border-left: 50px solid white;
  cursor: pointer;
}
.pause-icon {
  position: absolute;
  top: 45%;
  left: 46%;
  transform: translate(-55%, -35%);
  z-index: 2;
}
.pause-icon::before,
.pause-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 40px;
  background-color: white;
}

.pause-icon::before {
  transform: translateX(-10px);
}

.pause-icon::after {
  transform: translateX(10px);
}
.Video-Section .right-col > span .play-icon:hover {
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 40px solid white;
}
.Video-Section .right-col video {
  width: 100%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.Video-Section .left-col {
  flex: 0.5 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}
.Video-Section .left-col h3 {
  border-bottom: 5px solid #ff830c;
  width: 100%;
  font-size: 40px;
  line-height: 60px;
}
.Video-Section .left-col div {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.Video-Section .left-col span {
  width: 40%;
  margin: 2% auto auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f05161;
  flex-wrap: wrap;
  padding: 10px 20px;
  border-radius: 20px;
}
.Video-Section .left-col span:last-of-type {
  background: #9747ff;
  margin-bottom: 10%;
}
.Video-Section .left-col span h4 {
  font-size: 48px;
  line-height: 72px;
}
.Video-Section .left-col span p {
  font-size: 24px;
  line-height: 36px;
}

.MainContainer {
  max-width: 80%;
  margin: auto;
}
.MainContainer h3 {
  font-size: 48px;
  line-height: 72px;
}
.MainContainer ul li {
  font-size: 20px;
  line-height: 36px;
}
.MainContainer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MainContainer div img {
  width: 40%;
  height: 100%;
}
.MainContainer div:last-of-type {
  padding-bottom: 4%;
}
.MainContainer div:first-of-type ul:first-of-type {
  padding: 10px 30px 5px 0px;
}
.MainContainer div:last-of-type ul:last-of-type {
  padding: 5px 0px 5px 30px;
}
@media screen and (max-width: 768px) {
  .top-part {
    max-width: 100%;
    margin: auto;
    padding: 10px 0;
  }
  .top-part p {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    max-width: 80%;
    margin: auto;
  }
  .MainHeading {
    max-width: 90%;
    max-height: 10%;
    font-size: 40px;
    font-weight: 800;
    padding: 5px 0;
    margin: auto;
  }
  .MainHeading img {
    max-width: 25%;
    padding-right: 5px;
    margin-left: 5%;
  }
  .MainHeading .CaseStudyMainLogo {
    margin: 0;
    padding: 0;
  }
  .MainHeading .CaseStudyMainLogo h1 {
    font-size: 70%;
  }
  .MainHeading .CaseStudyMainLogo img {
    max-width: 20%;
    width: 100%;
  }
  .csbtn {
    display: none;
  }

  .Video-Section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .Video-Section .right-col {
    margin: 0% 2.5% 5% 2.5%;
    order: 2;
  }
  .Video-Section .right-col > span {
    display: none;
  }

  .Video-Section .right-col video {
    width: 100%;
    border-radius: 20px;
  }

  .Video-Section .left-col {
    order: 1;
  }
  .Video-Section .left-col h3 {
    border-bottom: 5px solid #ff830c;
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }
  .Video-Section .left-col div {
    flex-direction: row;
    margin: 3% 0;
  }
  .Video-Section .left-col span {
    width: 45%;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
  }
  .Video-Section .left-col span:last-of-type {
    margin-bottom: 0%;
  }

  .Video-Section .left-col span h4 {
    font-size: 24px;
    line-height: 36px;
  }
  .Video-Section .left-col span p {
    font-size: 14px;
    line-height: 21px;
  }

  .MainContainer {
    max-width: 80%;
    margin: auto;
  }
  .MainContainer p {
    margin-bottom: 4%;
  }
  .MainContainer h3 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 2%;
  }
  .MainContainer ul li {
    font-size: 14px;
    line-height: 21px;
    margin: 0 5%;
  }
  .MainContainer ul li:last-child {
    margin-bottom: 5%;
  }
  .MainContainer div {
    flex-direction: column;
    justify-content: center;
  }
  .MainContainer div img {
    width: 100%;
    height: 100%;
  }
  .MainContainer div:last-of-type {
    padding-bottom: 4%;
  }

  .MainContainer div:first-of-type ul:first-of-type {
    padding: 0;
    order: 2;
  }
  .MainContainer div:last-of-type ul:last-of-type {
    padding: 0;
  }
}
@media screen and (max-width: 360px) {
  .MainHeading {
    max-width: 100%;
  }
  .MainHeading img {
    max-width: 28%;
    margin-left: 5%;
  }
  .MainHeading .CaseStudyMainLogo h1 {
    font-size: 65%;
    padding: 0 0;
  }
  .MainHeading .CaseStudyMainLogo img {
    max-width: 25%;
    width: 100%;
  }
}
