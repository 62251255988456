.mobile-section {
  display: flex;
}
.iphone-x {
  position: relative;
  border-radius: 44px;
}
.iphone-x *,
.iphone-x *::before,
.iphone-x *::after {
  box-sizing: border-box;
  display: block;
}
.iphone-x .side {
  background: #000;
  border-radius: 44px;
  box-shadow: inset 0 0 2px 2px rgb(39, 137, 104), inset 0 0 0 6px aquamarine;
  height: 500px;
  padding: 15px 22px;
  width: 250px;
  z-index: 1;
}
.iphone-x .screen {
  background: #000;
  background-position: center center;
  background-size: cover;
  border-radius: 30px;
  position: relative;
  left: -7px;
  top: -4px;
  height: 466px;
  width: 220px;
  margin-left: -0px;
  margin-top: 6px;
  z-index: 198;
  overflow: hidden;
  object-fit: cover;
}
.iphone-x .screen video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.iphone-x .screen img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Notch class */
.iphone-x .header {
  background: #000000;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  height: 20px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 10px;
  width: 30px;
  z-index: 199;
}
/* .iphone-x .sensor-1::after,
.iphone-x .sensor-1::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-2::after,
.iphone-x .sensor-2::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-2::before {
  background: #333;
  border-radius: 2.5px;
  height: 5px;
  width: 42px;
  left: 45%;
  margin-left: -22px;
  top: 4px;
} */
.iphone-x .sensor-3::after,
.iphone-x .sensor-3::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-3::after {
  background: #2f2f2f;
  border-radius: 50%;
  box-shadow: inset 0 0.5px 2px 0.5px white;
  height: 12px;
  width: 12px;
  left: -80%;
  top: 2px;
  margin-left: 33px;
}
.iphone-x .volume-button {
  background: rgb(0, 0, 0);
  height: 26px;
  left: -2px;
  position: absolute;
  top: 92px;
  width: 3px;
}
.iphone-x .volume-button::after,
.iphone-x .volume-button::before {
  content: "";
  position: absolute;
  background: rgb(0, 0, 0);
  height: 50px;
  left: 0;
  width: 3px;
}
.iphone-x .volume-button::after {
  top: 48px;
}
.iphone-x .volume-button::before {
  top: 112px;
}
.iphone-x .power-button {
  background: rgb(0, 0, 0);
  height: 80px;
  right: -2px;
  position: absolute;
  top: 160px;
  width: 3px;
}
@media screen and (max-width: 900px) {
  /* .iphone-x .side{
       width: 150px;
       height: 300px;
     }
     .iphone-x .screen{
       width: 150px;
       height: 300px;
     } */
}

@media screen and (max-width: 768px) {
  .iphone-x .side {
    width: 200px;
    height: 400px;
  }
  .iphone-x .screen {
    width: 180px;
    border-radius: 30px;
    height: 374px;
    margin: 2px 0px 0px -5px;
  }
}
