.clientBanner {
  max-width: 100%;
}

.Clients-list-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  max-width: 900px;
}
.ClientsIntroInfo {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 0.5;
}
.ClientsIntroImg {
  flex: 0.5;
}
.ClientsIntroImg img {
  width: 100%;
  height: 90vh;
  margin-bottom: 20px;
}

.Clients-list-item-box {
  width: calc(800px / 4);
  height: 200px;
  border-radius: 30px;
  margin-bottom: 18px;
  background-color: #000;
  transition: all 0.5s ease-in;
  animation: forwards;
  cursor: cell;
}
.Clients-list-item-box:hover {
  transform: scale(1.1);
}
.Clients-list-item-box img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .Clients-list-items {
    width: 287px;
  }
  .Clients-list-item-box {
    width: 64px;
    height: 64px;
  }
  .ClientsIntroImg {
    order: 2;
    margin: auto;
  }
  .ClientsIntroImg img {
    width: 100%;
    height: 100%;
  }
  .ClientsIntroInfo {
    order: 1;
  }
}
