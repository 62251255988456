.BrandMobileVersion {
  display: none;
}

@media screen and (max-width: 768px) {
  .BrandMobileVersion {
    display: block;
    width: 100%;
    margin: auto;
  }
  .CarouselMobiles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    height: fit-content;
  }
  .CarouselMobileUpperimg,
  .CarouselMobileUpperimg3 {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .CarouselMobileUpperimg p,
  .CarouselMobileUpperimg3 p {
    text-align: center;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 24px;
  }
  .CarouselMobileUpperimg img {
    max-width: 200px;
  }
  .CarouselMobileUpperimg3 img {
    width: 140px;
    height: 55px;
  }
  .CarouselMobileImg {
    display: flex;
    margin: auto;
    justify-content: space-around;
  }

  .Influencer-Img-small {
    width: 80px;
    border-radius: 50px;
    border: 2px solid #ffecc5;
  }
  .Influencer-box-small {
    margin-top: 140px;
    padding-bottom: 50px;
    margin-left: -40px;
    margin-right: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Influencer-Name-small img {
    width: 20px;
    height: 20px;
  }
  .Influencer-Name-small span {
    padding: 0 2px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
  }
  .Influencer-Name-small {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 120px;
  }
  .second-influencer-box {
    margin: 20px 10px 20px 15px;
    margin-bottom: 90px;
  }
}
