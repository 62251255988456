.Btn {
  width: 150px;
  height: 50px;
  background-color: #ffffff;
  font-weight: bold;
  border-width: 2px;
  border-color: transparent;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  border-radius: 20px;
  font-weight: 700;
  margin-top: 20px;
  color: #000;
  font-size: 20px;
  transition: all 0.3s;
  cursor: pointer;
}

.Btn:hover {
  transform: translateY(-10px);
  background-color: rgb(105, 251, 214);
}

.Btn::after {
  transform: translateY(0);
}
