@import url("https://fonts.googleapis.com/css2?family=Lato&family=Lobster&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prompt:wght@700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background-size: 100% 100%;
  background-position: cover;
  height: 100vh;
}
a {
  text-decoration: none;
  color: #fff;
  transition: all 1s ease-in-out;
}
.header-link {
  list-style: none;
  text-decoration: none;
  color: #fff;
}
/* @media screen and (max-width:480px) {
  .App{
    height: 100vh;
    width: 100%;
  }
} */

.contact-us-div {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  height: 90vh;
}
.contact-us-div .contact-us-form-div {
  background-color: rgba(255, 255, 255, 0.895);
  border-radius: 50px;
  padding: 30px;
  height: 670px;
  width: 500px;
  border: 1.5px solid orange;
  box-shadow: -8px -8px 17px #f664, 10px 10px 30px #000,
    inset -1px -1px 2px #000, inset 1px 1px 3px #fff4;
}
.contact-us-div img {
  width: 550px;
  height: 550px;
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .contact-us-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: auto;
    padding-bottom: 20px;
    background-color: #000;
  }
  .contact-us-div .contact-us-form-div {
    width: 90%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
  }
  .contact-us-div img {
    width: 80%;
    margin: auto 10%;
    height: 20%;
  }
}
