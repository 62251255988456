.Landing-Header-div {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1rem;
  color: white;
  font-family: poppins;
  height: 10vh;
}

.Landing-Header-div .Landing-Header-navbar li {
  list-style: none;
  display: inline-block;
  margin-left: 2rem;
  font-size: 18px;
  font-family: inherit;
  cursor: pointer;
  transition-duration: 0.4s;
  transition-property: color;
  text-decoration: none;
}

.Landing-Header-div .Landing-Header-navbar li:focus,
.Landing-Header-div .Landing-Header-navbar li:hover {
  border-bottom: 2px solid orange;
}

.Landing-Header-navbar button {
  all: unset;
  padding: 0.5rem 1rem;
  margin-left: 2rem;
  border-radius: 12px;
  background-color: #ff830c;
}
.Landing-Header-navbar button:hover {
  border: none;
  box-shadow: inset 0 2px 6px #000000;
}

/* Hamburger menu */
.MobileHeader {
  display: none;
}

@media screen and (max-width: 768px) {
  .Landing-Header-div {
    display: none;
  }
  .MobileHeader {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0);
    height: auto;
    width: 100%;
  }
  .logo {
    font-size: 20px;
    line-height: 50px;
  }
  /* menu button */
  .menuBtn {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 221;
  }

  .menuBtn > span {
    color: #000000;
    background-color: #fff;
    border-radius: 1px;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -1px 0 0 -15px;
    transition: height 100ms;
  }

  .menuBtn > span:after,
  .menuBtn > span:before {
    content: "";
    background-color: #fff;
    border-radius: 1px;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    transition: all 200ms;
  }

  .menuBtn > span:after {
    top: -7px;
  }

  .menuBtn > span:before {
    bottom: -7px;
  }

  .menuBtn.act > span {
    height: 0;
  }

  .menuBtn.act > span:after,
  .menuBtn.act > span:before {
    background-color: #008877;
    top: 1px;
  }

  .menuBtn.act > span:after {
    transform: rotate(45deg);
  }

  .menuBtn.act > span:before {
    transform: rotate(-45deg);
  }

  .mainMenu {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 210;
    height: 100%;
    width: 100%;
    display: table;
    text-align: center;
    opacity: 0;
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: scale(0);
  }

  .mainMenu.act {
    opacity: 1;
    transform: scale(1);
  }

  .mainMenu.act ul li {
    opacity: 1;
    transform: translateX(0);
  }

  .mainMenu ul {
    display: table-cell;
    vertical-align: middle;
  }

  .mainMenu li {
    padding: 8px 0;
    transition: all 400ms 510ms;
    opacity: 0;
  }

  .mainMenu li:nth-child(odd) {
    transform: translateX(30%);
  }

  .mainMenu li:nth-child(even) {
    transform: translateX(-30%);
  }

  .mainMenu li:last-child {
    transform: none;
  }

  .mainMenu a {
    color: #000000;
    display: inline-block;
    font-size: 18px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .mainMenu a.suBtn {
    color: #fff;
  }

  .suBtn {
    background-color: #19b698;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 500ms ease-out;
  }
  .suBtn:hover,
  .suBtn:focus {
    box-shadow: inset 2px 3px 6px black;
  }
}
