.UI-Banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  height: 92vh;
  width: 100%;
  /* flex-wrap: wrap; */
  max-width: 1200px;
}

.UI-Banner h2 {
  line-height: 68px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 66px;
  line-height: 96px;
  text-align: center;
  color: #fff;
}
.UI-Banner p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .UI-Banner {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .UI-Banner h2 {
    font-size: 58px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .UI-Banner p {
    font-size: 16px;
    line-height: 28px;
  }
  .UI-Banner img {
    width: 100%;
    height: 450px;
  }
}
@media screen and (min-width: 361px) and (max-width: 420px) {
  .UI-Banner {
    margin-bottom: 30%;
  }
  .UI-Banner h2 {
    margin-top: 10%;
  }
  .UI-Banner p {
    font-size: 16px;
    line-height: 28px;
  }
  .UI-Banner img {
    width: 100%;
    height: 450px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1300px) {
  .UI-Banner h2 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .UI-Banner p {
    font-size: 16px;
    line-height: 28px;
  }
  .UI-Banner ~ img {
    width: 55px;
    height: 55px;
  }
}
