/* Studio banner csss------------------------------------------------------------------------------------------------------- */
.StudioBanner {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  object-fit: cover;
}
.StudioBanner h2 {
  margin-bottom: 20px;
}
.StudioIphone {
  position: relative;
  animation: iphonePop 1s ease-out 0s;
}
@keyframes iphonePop {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0);
  }
}
.StudioBanner .circle1 video,
.circle2 video,
.circle4 video,
.circle6 video,
.circle7 video,
.circle9 video,
.circle10 video,
.circle11 video,
.circle12 video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.circle1 img,
.circle2 img,
.circle3 img,
.circle4 img,
.circle5 img,
.circle6 img,
.circle7 img,
.circle8 img,
.circle9 img,
.circle10 img,
.circle11 img,
.circle12 img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.StudioBanner .circle9 video {
  object-fit: fill;
}
.circle1,
.circle2,
.circle3,
.circle4,
.circle5,
.circle6,
.circle7,
.circle8,
.circle9,
.circle10,
.circle11,
.circle12 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #3f3f3f;
  border-radius: 50%;
  animation: bubblePop 2s ease-out 0s normal backwards;
  animation-delay: 1s;
  object-fit: cover;
  filter: drop-shadow(5px 81px 32px rgba(0, 0, 0, 0.04))
    drop-shadow(3px 45px 27px rgba(0, 0, 0, 0.13))
    drop-shadow(1px 20px 20px rgba(0, 0, 0, 0.22))
    drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.26))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.26));
}
.circle1 {
  top: 15%;
  left: 32%;
  width: 135px;
  height: 135px;
  border: 3px solid #eee0ca;
}
.circle2 {
  top: 36%;
  left: 27%;
  width: 201px;
  height: 201px;
  border: 5px solid #fff373;
}
.circle3 {
  top: 67%;
  left: 27%;
  width: 160px;
  height: 160px;
  border: 5px solid #faab78;
}
.circle4 {
  top: 10%;
  left: 17%;
  width: 216px;
  height: 216px;
  border: 5px solid #fbf8f1;
}
.circle5 {
  top: 30%;
  left: 10%;
  width: 126px;
  height: 126px;
  border: 5px solid #ffffff;
}
.circle6 {
  top: 48%;
  left: 12%;
  width: 214px;
  height: 217px;
  border: 5px solid #fcf9be;
}
.circle7 {
  left: 59%;
  top: 12%;
  width: 180px;
  height: 180px;
  border: 2px solid #f6ffde;
}
.circle8 {
  left: 64%;
  top: 37%;
  border: 3px solid #ffabab;
}
.circle9 {
  left: 60%;
  top: 66%;
  width: 140px;
  height: 140px;
  border: 3px solid #eff3f4;
}
.circle10 {
  top: 7%;
  left: 73%;
  width: 230px;
  height: 230px;
  border: 6px solid #f9f9f9;
}
.circle11 {
  top: 66%;
  left: 73%;
  width: 160px;
  height: 160px;
  border: 5px solid #fff9ca;
}
.circle12 {
  top: 42%;
  left: 80%;
  border: 5px solid #faab78;
}
@keyframes bubblePop {
  0% {
    left: 42%;
    top: 45%;
    opacity: 0;
  }
  25% {
    transform: translate(40%, 0%);
  }
  75% {
    transform: translate(20%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

/* heading para Csss------------------------------------------------------------------------------------------ */
.StudioBanner h2 {
  font-family: Lobster, Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  line-height: 120px;
  color: #fff;
}
.StudioSection {
  background: #000;
  color: #fff;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  text-align: center;
  height: auto;
}
.StudioSection h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 96px;
}
.StudioSection p {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
.StudioSection h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
}
@media screen and (min-width: 900px) and (max-width: 1300px) {
  .Studiologo {
    display: none;
  }
  .StudioBanner h2 {
    font-size: 74px;
    margin-bottom: 0;
  }

  .circle1 {
    left: 28%;
  }
  .circle2 {
    width: 160px;
    height: 160px;
    left: 20%;
    top: 38%;
  }
  .circle4 {
    left: 10%;
  }
  .circle5 {
    top: 40%;
    left: 4%;
  }
  .circle4,
  .circle6 {
    width: 200px;
    height: 200px;
  }
  .circle6 {
    top: 60%;
    left: 10%;
  }
  .circle7,
  .circle11 {
    width: 160px;
    height: 160px;
  }
  .circle7 {
    left: 61%;
  }
  .circle8 {
    width: 150px;
    height: 150px;
  }
  .circle9 {
    top: 60%;
    left: 62%;
  }
  .circle10 {
    left: 75%;
  }
  .circle10,
  .circle12 {
    width: 200px;
    height: 200px;
  }
  .circle11 {
    width: 160px;
    height: 160px;
  }
  .circle12 {
    top: 36%;
  }
}
@media screen and (max-width: 768px) {
  .circle1,
  .circle2,
  .circle4,
  .circle6,
  .circle7,
  .circle9,
  .circle10,
  .circle11,
  .circle12 {
    display: none;
  }
  .circle3 {
    top: 70%;
    left: 58%;
    z-index: 200;
  }
  .circle5 {
    top: 45%;
    left: 20%;
    z-index: 200;
  }
  .circle8 {
    top: 25%;
    left: 60%;
    z-index: 200;
  }
  .StudioSection {
    height: auto;
  }
  .StudioSection h2 {
    font-size: 32px;
    line-height: 48px;
  }
  .StudioSection p {
    font-size: 14px;
    line-height: 21px;
  }
  .StudioSection h3 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 480px) {
  .circle3 {
    left: 64%;
    width: 116px;
    height: 116px;
  }
  .circle8 {
    width: 138px;
    height: 138px;
    left: 66%;
  }
  .circle5 {
    left: 10%;
    width: 95px;
    height: 95px;
  }
}
@media screen and (max-width: 400px) {
  .circle3,
  .circle5,
  .circle8 {
    display: none;
  }
}

/* Caraousel csss-------------------------------------------------------------------------------------------------------------------- */

.StudioCarousel {
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  cursor: grab;
}

.carousel-item {
  --items: 10;
  --width: clamp(150px, 30vw, 300px);
  --height: clamp(200px, 40vw, 400px);
  --x: calc(var(--active) * 800%);
  --y: calc(var(--active) * 200%);
  --rot: calc(var(--active) * 120deg);
  --opacity: calc(var(--zIndex) / var(--items) * 3 - 2);
  overflow: hidden;
  position: absolute;
  z-index: var(--zIndex);
  width: var(--width);
  height: var(--height);
  margin: calc(var(--height) * -0.5) 0 0 calc(var(--width) * -0.5);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  user-select: none;
  transform-origin: 0% 100%;
  box-shadow: 0 10px 50px 10px rgba(0, 0, 0, 0.5);
  background: black;
  pointer-events: all;
  transform: translate(var(--x), var(--y)) rotate(var(--rot));
  transition: transform 0.8s cubic-bezier(0, 0.02, 0, 1);
  background-color: #fff;
}
@media screen and (min-width: 1100px) {
  .carousel-item {
    --width: 480px;
    --height: 400px;
  }
}
.carousel-box {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  opacity: var(--opacity);
  font-family: Poppins, Arial, Helvetica, sans-serif;
  box-shadow: 0 2px 20px black;
}
.carousel-box::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5)
  );
}

.carousel-item img {
  width: 95%;
  margin-top: 3%;
  height: 75%;
  object-fit: cover;
  pointer-events: none;
}
.carousel-item p {
  z-index: 3;
  color: #000;
  font-weight: 700;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #ff8300;
}
@media screen and (max-width: 480px) {
  .carousel-item {
    width: 250px;
    height: 250px;
  }
  .carousel-item p {
    font-size: 14px;
    line-height: 15px;
  }
}

/* Article 3 -----------------------------------------------------------------------------------------------------------------------------*/
.StudioSection article {
  max-width: 1200px;
  margin: auto auto 50px auto;
}
.StudioSection article:nth-child(1) {
  max-width: 100%;
}

.StudioSection article:nth-child(3) {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto auto 50px auto;
}
.StudioSection article:nth-child(3) img {
  width: 555px;
  height: 555px;
}
.StudioSection article:nth-child(3) h2 {
  font-size: 48px;
  line-height: 72px;
  text-align: left;
}
.StudioSection article:nth-child(3) p {
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}
.StudioSection article:nth-child(4) .StudioCarousel2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.StudioSection article:nth-child(4) .cracked-div {
  background-image: url("../Assets/Images/studio section/piece of paper 2.png"); /* replace with your image */
  background-position: center bottom top;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 900px;
  height: 500px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.StudioSection article:nth-child(4) .cracked-div h3 {
  font-size: 64px;
  line-height: 96px;
  z-index: 1;
  color: #ff8300;
  margin-top: 10px;
  width: 80%;
  margin-top: 50px;
}
.StudioSection article:nth-child(4) .cracked-div p {
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  z-index: 1;
  color: #000000;
}
.StudioSection article:nth-child(4) .follow {
  font-weight: 700;
  font-size: 40px;
  line-height: 72px;
  z-index: 1;
  color: #ff8300;
  margin-top: 10px;
}
.StudioSection article:nth-child(4) .follow img {
  width: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.StudioSection article:nth-child(5) iframe {
  width: 1200px;
  height: 455px;
  margin-top: 40px;
  border-radius: 30px;
  /* margin-bottom: 20px; */
}

.StudioSection article:nth-child(6) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: transparent;
  border: 5px solid #ff8300;
  border-radius: 20px;
  padding: 20px;
}
.StudioSection article:nth-child(6) div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1000px;
  margin: auto;
  border-bottom: 5px solid #fff;
  margin-bottom: 20px;
  padding-bottom: 20px;
  height: 200px;
}

.StudioSection article:nth-child(6) div:nth-child(2) p {
  text-align: left;
  height: 200px;
  width: 1000px;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
}

@media screen and (max-width: 768px) {
  /* article 3 media querry------------------------------ */
  .StudioSection article:nth-child(3) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .StudioSection article:nth-child(3).StudioArticle3img {
    order: 2;
  }
  .StudioSection article:nth-child(3) .StudioArticle3info {
    order: 1;
  }
  .StudioSection article:nth-child(3) img {
    width: 287px;
    height: 295px;
  }
  .StudioSection article:nth-child(3) h2 {
    font-size: 24px;
    line-height: 36px;
    margin: 10px auto 10px auto;
    text-align: center;
  }
  .StudioSection article:nth-child(3) p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  /* article 4 media querry css---------------------------------- */
  .StudioSection article:nth-child(4) .StudioCarousel2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .StudioSection article:nth-child(4) .cracked-div {
    width: 280px;
    height: 200px;
    margin: auto;
    z-index: 1;
  }
  .StudioSection article:nth-child(4) .cracked-div h3 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
  }
  .StudioSection article:nth-child(4) .cracked-div img {
    width: 20px;
    height: 15px;
    padding-right: 5px;
  }
  .StudioSection article:nth-child(4) .cracked-div p {
    font-size: 18px;
    line-height: 26px;
    z-index: 1;
    color: #000000;
  }
  /* I frame ------------------------------------------------------------------------ */
  .StudioSection article:nth-child(5) img {
    width: 16px;
    margin-left: 2px;
    margin-right: 2px;
    /* margin-bottom: 20px; */
  }
  .StudioSection article:nth-child(5) iframe {
    width: 304px;
    height: 675px;
    /* margin-bottom: 20px; */
  }

  .StudioSection article:nth-child(6) {
    width: auto;
    height: auto;
    padding: 10px;
  }
  .StudioSection article:nth-child(6) div:nth-child(1) {
    width: 310px;
    margin: auto;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .StudioSection article:nth-child(6) div:nth-child(1) h2 {
    font-size: 24px;
    line-height: 36px;
    padding-left: 10px;
  }
  .StudioSection article:nth-child(6) div:nth-child(2) p {
    font-size: 14px;
    line-height: 29px;
    max-width: 300px;
    text-align: left;
    height: fit-content;
    margin-top: 10px;
  }
}
