.mobile-section {
  display: flex;
}
.iphone-x {
  position: relative;
  box-shadow: 14px 12px 0px 6px black;
  border-radius: 44px;
}
.iphone-x *,
.iphone-x *::before,
.iphone-x *::after {
  box-sizing: border-box;
  display: block;
}
.iphone-x .side {
  background: #000;
  border-radius: 44px;
  box-shadow: inset 0 0 2px 2px rgb(255, 255, 255),
    inset 0 0 0 6px rgb(255, 255, 255);
  height: 500px;
  padding: 15px 22px;
  width: 250px;
  z-index: 1;
}
.iphone-x .screen {
  background: #000;
  background-position: center center;
  background-size: cover;
  border-radius: 30px;
  position: relative;
  left: -7px;
  top: -4px;
  height: 466px;
  width: 220px;
  margin-left: -0px;
  margin-top: 6px;
  z-index: 198;
  overflow: hidden;
  object-fit: cover;
}
.iphone-x .screen video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Notch class */
.iphone-x .header {
  background: #000000;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  height: 20px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 10px;
  width: 94px;
  z-index: 199;
}
.iphone-x .sensor-1::after,
.iphone-x .sensor-1::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-2::after,
.iphone-x .sensor-2::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-2::before {
  background: #333;
  border-radius: 2.5px;
  height: 5px;
  width: 42px;
  left: 45%;
  margin-left: -22px;
  top: 4px;
}
.iphone-x .sensor-3::after,
.iphone-x .sensor-3::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-3::after {
  background: #222;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  left: 42%;
  top: 2px;
  margin-left: 33px;
}
.iphone-x .volume-button {
  background: rgb(255, 255, 255);
  height: 26px;
  left: -2px;
  position: absolute;
  top: 92px;
  width: 3px;
}
.iphone-x .volume-button::after,
.iphone-x .volume-button::before {
  content: "";
  position: absolute;
  background: rgb(255, 255, 255);
  height: 50px;
  left: 0;
  width: 3px;
}
.iphone-x .volume-button::after {
  top: 48px;
}
.iphone-x .volume-button::before {
  top: 112px;
}
.iphone-x .power-button {
  background: rgb(255, 255, 255);
  height: 80px;
  right: -2px;
  position: absolute;
  top: 160px;
  width: 3px;
}
@media screen and (max-width: 900px) {
  /* .iphone-x .side{
       width: 150px;
       height: 300px;
     }
     .iphone-x .screen{
       width: 150px;
       height: 300px;
     } */
}

@media screen and (max-width: 480px) {
  .iphone-x {
    box-shadow: none;
    border-radius: 35px;
  }
  .iphone-x .side {
    border-radius: 35px;
    width: 200px;
    height: 400px;
  }
  .iphone-x .screen {
    width: 180px;
    border-radius: 35px;
    height: 380px;
    margin: 0px 0 0 -4px;
  }
  .iphone-x .side {
    box-shadow: inset 0 0 2px 2px rgb(255, 255, 255),
      inset 0 0 0 6px rgb(255, 255, 255);
  }
}

/* emojis cssss */
.iphone-x .emoji2 {
  position: absolute;
  left: 110%;
  top: 0%;
  width: 50%;
  height: 100%;
}
.iphone-x .emoji1 {
  position: absolute;
  left: -52%;
  top: 0%;
  width: 50%;
  height: 100%;
}
.iphone-x .screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .iphone-x .emoji2 {
    position: absolute;
    left: 106%;
    top: 4%;
    width: 100px;
    height: 100%;
  }
  .iphone-x .emoji1 {
    position: absolute;
    left: -52%;
    top: 8%;
    width: 100px;
    height: 100%;
  }
}
@media screen and (max-width: 480px) {
  .iphone-x .emoji2 {
    position: absolute;
    left: 103%;
    top: 0%;
    width: 36%;
  }
  .iphone-x .emoji1 {
    position: absolute;
    left: -40%;
    top: 0%;
    width: 36%;
  }
}
