@import url("https://fonts.googleapis.com/css2?family=Lato&family=Prompt:wght@700;900&display=swap");
.Main-footer {
  background: #000;
  color: #fff;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  border: none;
}
.Main-footer-box {
  margin: auto;
  padding: 20px 10px 90px 10px;
}
.Footer-Content-Container {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-around;
  margin: 40px auto 10px auto;
}
.Social-Icons img {
  margin: 10px;
  cursor: pointer;
  transition: all 1s ease-in;
}
.Social-Icons img:hover {
  transform: scale(1.1);
}

.Footer-Content-Container h4 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.Footer-Content-Container li,
.Footer-Content-Container p {
  list-style: none;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  mix-blend-mode: normal;
}
.Footer-Content-Container li {
  padding: 6px;
  transition: all 0.5s ease-in;
}
.Footer-Content-Container li:hover {
  border-bottom: 1px solid white;
  cursor: pointer;
}
.Footer-Content-Container .Address-1,
.Address-2,
.Address-3 {
  width: 191px;
  height: 282px;
}
.Footer-Content-Container .Address-1 p,
.Address-2 p,
.Address-3 p {
  padding: 10px 0;
}
.CopyRight {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 90px;
  margin-bottom: -60px;
  color: #ffffffe8;
  text-align: center;
}
.Footer-Content-Container button {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .Footer-Content-Container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    padding: 10px 5px 10px 5px;
  }
  .Footer-Content-Container h4 {
    text-align: center;
  }

  .Site-map ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0px;
    max-width: 200px;
    margin: auto;
  }
  .Site-map ul li {
    font-size: 14px;
  }
  .Footer-Content-Container .Address-1,
  .Address-2,
  .Address-3 {
    width: auto;
    height: auto;
  }
  .Footer-Content-Container .Address-1 p,
  .Address-2 p,
  .Address-3 p {
    width: 141px;
    height: auto;
    margin: auto;
    text-align: center;
  }
}
