.ServicesBanner p {
  text-align: left;
  padding-bottom: 20px;
}

.Servies-Page-Card-Container,
.Services-Page-card-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.Services-Page-card-box {
  max-width: 900px;
  margin-bottom: 20px;
  border-radius: 30px;
  padding-top: 10px;
}
.Services-Page-card-box h3 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 66px;
  color: #fff;
}
.Services-Page-card-box .Services-Page-card-box-info p {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
}
.Services-Page-card-box .Services-Page-card-box-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

.Services-Page-card-box img {
  width: 300px;
  height: 300px;
  border-bottom-left-radius: 30px;
  flex: 0.3;
}
.Services-Page-card-box .Services-Page-card-box-info p {
  flex: 0.7;
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .ServicesBanner {
    height: auto;
  }
  .ServicesBanner h2 {
    font-size: 54px;
    padding-bottom: 20px;
  }
  .ServicesBanner p {
    text-align: center;
    padding-bottom: 20px;
  }
  .Services-Page-card-box {
    width: 95%;
    height: 240px;
  }
  .Services-Page-card-box h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
  }
  .Services-Page-card-box .Services-Page-card-box-info img {
    width: 126px;
    height: 168px;
    margin-bottom: -40px;
  }
  .Services-Page-card-box .Services-Page-card-box-info p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
  }
}
