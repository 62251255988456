.Landing-Recent-Brands-contain {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  margin-top: 10rem;
  width: 1300px;
  height: 400px;
  border-radius: 20px;
  position: relative;
}
.Landing-Recent-Brands-contain .Sticker-info {
  cursor: pointer;
  background-color: #000;
  position: absolute;
  left: 4.5%;
  top: -29.5%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  height: 478px;
  width: 227px;
  z-index: 200;
  border-radius: 34px;
  color: #fff;
  padding-top: 10px;
  z-index: 198;
}
.Landing-Recent-Brands-contain .Sticker-info2 {
  background: #e84429;
}
.Landing-Recent-Brands-contain .Sticker-info3 {
  background: #fff;
  color: #000;
}

.Landing-Recent-Brands-contain .Sticker-info img {
  width: 150px;
  margin: 30px 0 20px 0;
  cursor: pointer;
}
.Landing-Recent-Brands-contain .Sticker-info p {
  font-size: 13.5px;
  padding: 12px;
  text-align: center;
}
/* .Landing-Recent-Brands-contain .CaseStudies-Iphone-Secton .iphone-x {
  box-shadow: 8px 100px 6px rgba(0, 0, 0, 0.65);
} */
.Landing-Recent-Brands-contain:nth-child(3) {
  background-color: #f48164;
}

/* check----------------------------------------------Check---------------------------------------------------------------------------------- */

.Landing-Recent-Brands-contain .CaseStudies-Iphone-Secton {
  margin-top: -10rem;
}
.Landing-Brand-influcencer-info {
  margin-top: -12rem;
}

.CaseStudies-Iphone-Secton {
  cursor: pointer;
}

.Landing-Brand-influcencer-info {
  display: flex;
  align-items: center;
  text-align: center;
}
.Landing-Brand-influcencer-info div {
  margin-left: 40px;
}

.Landing-Recent-Brands-contain .Influencer-Img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}
.Landing-Recent-Brands-contain .Influencer-Img:hover {
  transform: scale(1.05);
}
.Landing-Recent-Brands-contain p {
  font-size: 20px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.Influencer-Name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  color: #fff;
  font-weight: 700;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}
.Landing-Recent-Brands-contain .Influencer-Name img {
  width: 25px;
  margin-right: 8px;
}

.Landing-Recent-Brands-contain .Influencer-info-Img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 130px;
  padding: 10px 5px;
}

.Landing-Brand-influcencer-info {
  position: relative;
}
.Landing-Brand-influcencer-info .Animated-Mobile-section {
  position: absolute;
  cursor: pointer;
  top: -70%;
}

.Animated-Mobile-section:nth-child(1) div {
  cursor: pointer;
  margin: auto;
  /* box-shadow: none; */
  /* box-shadow: inset 0 0 0 6px rgb(200, 255, 3); */
}

.Animated-Mobile-section-0 {
  left: -1%;
  transform: translateY(64%);
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.Animated-Mobile-section3-0 {
  left: -1%;
  transform: translateY(36%);
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.Animated-Mobile-section-0-active-0 {
  left: -1%;
  transform: translateY(64%);
  opacity: 1;
  z-index: 10;
  transition: opacity 1s ease-in-out;
}
.Animated-Mobile-section3-0-active-0 {
  left: -1%;
  transform: translateY(36%);
  opacity: 1;
  z-index: 10;
  transition: opacity 1s ease-in-out;
}
.Influencer-box {
  z-index: 1;
}

.Landing-Recent-Brands-contain .Animated-Mobile-section-1 {
  opacity: 0;
  left: 32%;
  transform: translateY(64%);
  transition: opacity 1s ease-in-out;
}
.Landing-Recent-Brands-contain .Animated-Mobile-section3-1 {
  opacity: 0;
  left: 32%;
  transform: translateY(36%);
  transition: opacity 1s ease-in-out;
}
.Animated-Mobile-section-1-active-1 {
  opacity: 1;
  left: 32%;
  transform: translateY(64%);
  z-index: 10;
  transition: opacity 1s ease-in-out;
}
.Animated-Mobile-section3-1-active-1 {
  opacity: 1;
  left: 32%;
  transform: translateY(36%);
  z-index: 10;
  transition: opacity 1s ease-in-out;
}

.Animated-Mobile-section-2 {
  opacity: 0;
  left: 65%;
  transform: translateY(64%);
  transition: opacity 1s ease-in-out;
}
.Animated-Mobile-section3-2 {
  opacity: 0;
  left: 66%;
  transform: translateY(36%);
  transition: opacity 1s ease-in-out;
}

.Animated-Mobile-section-2-active-2 {
  opacity: 1;
  left: 65%;
  transform: translateY(64%);
  z-index: 999;
  transition: opacity 1s ease-in-out;
}
.Animated-Mobile-section3-2-active-2 {
  opacity: 1;
  left: 66%;
  transform: translateY(36%);
  z-index: 999;
  transition: opacity 1s ease-in-out;
}
.CaseStudies-Iphone-Secton-vanish {
  margin-top: -10rem;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

@media screen and (max-width: 768px) {
  .Landing-Recent-Brands-contain {
    display: none;
  }
}
