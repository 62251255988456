.Subheading-and-Info h2 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 82px;
  text-align: center;
  color: #000000;
  margin: 20px auto 2px auto;
  max-width: 1200px;
  padding: 2rem 0.5rem;
}

.Subheading-and-Info p {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  max-width: 1000px;
  padding: 0 0.5rem 2rem 0.5rem;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .Subheading-and-Info h2 {
    font-size: 36px;
    line-height: 54px;
    margin-top: 50px;
  }
  .Subheading-and-Info p {
    font-size: 14px;
    line-height: 18px;
    max-width: 350px;
  }
}
