/* Landing Banner css------------------------------------------------------------*/
.Landing-banner-intro {
  margin-left: -2%;
}
.Landing-Banner-para span {
  margin-right: 12px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  display: block;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: left;
}
.Landing-Banner-para span:nth-child(1),
.Landing-Banner-para span:nth-child(3) {
  animation: first 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes first {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.Landing-Banner-para span:nth-child(2),
.Landing-Banner-para span:nth-child(4) {
  animation: secound 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes secound {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.Landing-banner-contact-btn {
  animation: down 3.5s ease-in forwards;
}
@keyframes down {
  0% {
    transform: translateY(100%) scale(2.5);
    opacity: 0;
  }
  50% {
    transform: translateY(-50%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
  }
}

.Landing-banner-iphone {
  animation: third 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes third {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/* Landing banner main logo css -------------------------------------------------*/
.Landing-main-Header {
  justify-content: flex-start;
  margin-bottom: 20px;
  animation: header 4s ease-in forwards;
}
@keyframes header {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Landing-main-Header h1 {
  font-size: 48px;
}
.Landing-main-Header img {
  width: 70px;
}
/* Landing media querry for mobile------------------------------------------------- */
@media screen and (min-width: 401px) and (max-width: 768px) {
  .Landing-main-Header {
    display: none;
  }
  .Landing-banner-intro {
    flex: 0.5 50%;
    margin: 10% 0;
  }
  .Landing-banner-iphone {
    flex: 0.5 50%;
  }
  .Landing-Banner-para span {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .Landing-Banner-para span:first-child {
    margin-top: -15px;
  }
}
@media screen and (min-width: 361px) and (max-width: 400px) {
  .Landing-main-Header {
    display: none;
  }

  .Landing-Banner-para span {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .Landing-banner-intro {
    flex: 0.5 50%;
    margin: 8% 0;
  }
}
@media screen and (min-width: 331px) and (max-width: 360px) {
  .Landing-main-Header {
    display: none;
  }

  .Landing-Banner-para span {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}
@media screen and (max-width: 330px) {
  .Landing-main-Header {
    display: none;
  }

  .Landing-Banner-para span {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .Landing-Banner {
    max-width: 1050px;
  }
  .Landing-Banner-para span {
    font-size: 36px;
    line-height: 60px;
  }
  .Landing-Banner-para span:first-child {
    margin-top: -15px;
  }
}

/* Landing our Services Section -----------------------------------------------------*/
.Services-intro img {
  width: 206px;
  margin: 1% 44.5%;
}
/* --------------------------------------------------- */
.Landing-Page-Services-content-row-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  max-width: 1100px;
  flex-wrap: wrap;
}

.Landing-Page-Services-content-row-1 .Service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 355px;
  height: 550px;
  margin-bottom: 20px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.Landing-Page-Services-content-row-1 .Service-item h3 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  width: auto;
  height: auto;
}
.Landing-Page-Services-content-row-1 .Service-item:nth-child(2) h3 {
  height: 100px;
}

.Landing-Page-Services-content-row-1 .Service-item p {
  opacity: 0;
  display: none;
  margin-left: 50px;
  margin-right: 5px;
  color: #fff;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.Landing-Page-Services-content-row-1 .Service-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: -50px;
}
.Landing-Page-Services-content-row-1 .Service-item div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease-out;
}

.Service-item.active,
.Service-item.active2 {
  width: 800px;
  background-color: lightblue;
}

.Service-item.active div,
.Service-item.active2 div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 300px;
}

.Service-item.active img,
.Service-item.active2 img {
  width: 350px;
  height: 350px;
  margin-left: 20px;
  border-radius: 50%;
}
.Landing-Page-Services-content-row-1 .Service-item.active p,
.Landing-Page-Services-content-row-1 .Service-item.active2 p {
  display: block;
  opacity: 1;
  max-height: 500px;
  transition-delay: 0.5s;
}

.Service-item.notActive,
.Service-item.notActive2 {
  width: 100px;
}

.Service-item.notActive img,
.Service-item.notActive2 img {
  display: none;
}

.Service-item.notActive h3,
.Service-item.notActive2 h3 {
  transform: rotate(-90deg);
  font-size: 24px;
  line-height: 100px;
  white-space: nowrap;
}
.Landing-Page-Services-content-for-mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .Services-intro img {
    width: 150px;
    margin: -2% 30% 2% 30%;
  }
  .Landing-Page-Services-content-row-1 {
    display: none;
  }
  .Landing-Page-Services-content-for-mobile {
    display: flex;
    width: 320px;
    flex-wrap: wrap;
    margin: auto;
    height: auto;
  }
  .Landing-Page-Services-content-for-mobile .Service-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 220px;
    border-radius: 20px;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .Landing-Page-Services-content-for-mobile .Service-item h3 {
    color: #fff;
    text-align: center;
    padding: 10px;
    height: auto;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
  }
  .Landing-Page-Services-content-for-mobile .Service-item p {
    display: none;
  }
  .Landing-Page-Services-content-for-mobile .Service-item img {
    width: 100%;
    height: 150px;
    border-radius: 20px;
  }
  .Landing-Page-Services-content-for-mobile .Service-item:nth-child(1) img {
    width: 150px;
    height: 135px;
    margin-bottom: 5px;
  }
  .Landing-Page-Services-content-for-mobile .Service-item:nth-child(2) img {
    width: 150px;
    height: 160px;
    margin-bottom: -15px;
  }
}

/* Studio css -------------------------------------------------------------------------------- */
.Landing-page-studio {
  width: 1200px;
  height: 1000px;
  background-color: #000;
  color: #fff;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 40px;
}
/* Company logo from logo.js */
.Landing-page-studio .LandingStudio-section-header {
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 40px auto auto auto;
}

.Landing-page-studio h3 {
  font-family: Lobster, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 90px;
  margin: 10px auto;
}

.Landing-page-studio p {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  max-width: 800px;
  margin: 10px auto 0 auto;
  color: #fefcf5;
}
.Landing-page-studio button:hover {
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.75) inset;
  cursor: pointer;
}

/* Img css for studio div */
.Studio-img-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  position: relative;
}
.Studio-img-container .Studiopins {
  width: 35px;
  height: 45.08px;
  position: absolute;
  top: -6.5%;
  left: 50%;
  z-index: 100;
}
.Studio-img-container .StudioLikes {
  width: 92.24px;
  height: 44.41px;
  position: absolute;
  left: -8%;
  top: -12%;
  transform: rotate(-15deg);
}
.Studio-img-div2 .StudioLikes {
  width: 63.77px;
  height: 44.41px;
  position: absolute;
  top: -6.5%;
  left: 85%;
  transform: rotate(20deg);
  z-index: 100;
}
.Studio-img-div3 .StudioLikes {
  width: 90px;
  height: 76px;
  left: 70%;
  top: -15%;
  transform: rotate(-20deg);
}
.Studio-img-div1,
.Studio-img-div2,
.Studio-img-div3 {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  width: 352.95px;
  /* height: 300px; */
  margin-left: 10px;
  padding-top: 18px;
  padding-left: 15px;
  padding-right: 15px;
}
.Studio-img-div1 {
  height: 310px;
}
.Studio-img-div2 {
  width: 305px;
  height: 360px;
}
.Studio-img-div3 {
  height: 325px;
}
.Studio-img-div1 {
  transform: rotate(15deg);
  z-index: 2;
}
.Studio-img-div3 {
  transform: rotate(25deg);
}
.Studio-img-div2 {
  transform: rotate(-15deg);
}
.Studio-img-div1 img {
  width: 324.61px;
  height: 247.73px;
}
.Studio-img-div2 img {
  width: 270px;
  height: 280px;
}
.Studio-img-div3 img {
  width: 324.61px;
  height: 247.73px;
}
.Studio-img-div1 p,
.Studio-img-div2 p,
.Studio-img-div3 p {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 18px;
}
.Studio-img-div1 p {
  color: #000;
  margin-top: -2px;
}
.Studio-img-div2 p {
  color: #ff7262;
  margin-top: 5px;
  z-index: 200;
}
.Studio-img-div3 p {
  color: #0acf83;
  margin-top: -2px;
  line-height: 25px;
}

@media screen and (max-width: 768px) {
  .Landing-page-studio .LandingStudio-section-header img {
    max-width: 20px;
  }
  .Landing-page-studio {
    width: 323px;
    height: auto;
    margin: auto;
    padding-bottom: 70px;
  }
  .Landing-page-studio h3 {
    font-size: 36px;
    line-height: 45px;
  }
  .Landing-page-studio p {
    font-size: 12px;
    line-height: 18px;
    width: 280px;
  }
  .Studio-img-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .Studio-img-div1,
  .Studio-img-div2 {
    width: 185px;
    height: 155px;
    margin: auto;
  }
  .Studio-img-div1 img,
  .Studio-img-div2 img {
    width: 175px;
    margin: auto;
    height: 107px;
    margin-left: -10px;
    margin-top: -10px;
  }
  .Studio-img-div1 {
    z-index: 4;
    margin: 10px auto;
  }
  .Studio-img-div2 {
    z-index: 3;
    transform: rotate(-30deg);
  }
  .Studio-img-div3 {
    display: none;
  }
  .Studio-img-div1 p,
  .Studio-img-div2 p {
    width: auto;
    font-size: 12px;
    line-height: 18px;
    height: auto;
  }
  .Studio-img-div2 p {
    color: #df0000;
  }
  .Studio-img-div2 .StudioLikes {
    width: 40px;
    height: 30px;
    top: -9.5%;
    left: 0%;
    transform: rotate(-10deg);
    z-index: 100;
  }
  .Studio-img-div1 .StudioLikes {
    width: 65px;
    height: 30px;
    top: -9.5%;
    left: 80%;
    transform: rotate(30deg);
    z-index: 100;
  }
  .Studio-img-container .Studiopins {
    left: 45%;
    top: -14%;
  }
  .Studio-img-div2 .Studiopins {
    left: 45%;
  }
}

/* Landing Number of Success section ------------------------------------------------------------------------------------------------ */
.Landing-Success-Container {
  height: auto;
}

/* Cse Studies part 2 ------------------------------------------------------------------------------------------------------------- */
.Case-Studies .LandingHeaderInfo-info {
  max-width: 1000px;
  margin: auto;
}

.Landing-Case-Study-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.Landing-Case-Study-img img {
  width: 576px;
  height: 340px;
  border-radius: 20px;
  margin-left: 20px;
  transition: all 0.5s ease-in;
}

.VitaCaseStudy-img {
  position: relative;
  z-index: 1;
  height: 340px;
  cursor: pointer;
  transition: all 1s ease-in;
}
.VitaCaseStudy-img p {
  opacity: 0;
}
.MojCaseStudy-box {
  position: relative;
  z-index: 1;
  height: 340px;
}
.MojCaseStudy-box p {
  opacity: 0;
}
.Landing-Case-Study-img .VitaCaseStudy-img:hover .background,
.Landing-Case-Study-img .MojCaseStudy-box:hover .background {
  background: rgba(0, 0, 0, 0.55);
  width: 96.5%;
  height: 100%;
  border-radius: 20px;
  z-index: 3;
  position: absolute;
  top: 0%;
  left: 3.5%;
  transition: all 0.5s ease-in;
}
.VitaCaseStudy-img:hover p:nth-child(3),
.MojCaseStudy-box:hover p:nth-child(3) {
  opacity: 1;
  position: absolute;
  z-index: 3;
  color: #fff;
  top: 4%;
  left: 70%;
  padding-bottom: 30px;
  font-weight: 600;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 16px;
  transition: all 0.5s ease-in;
}

.VitaCaseStudy-img:hover p:nth-child(4),
.MojCaseStudy-box:hover p:nth-child(4) {
  position: absolute;
  opacity: 1;
  z-index: 3;
  color: #fff;
  top: 86%;
  left: 25%;
  font-weight: 600;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 16px;
  transition: all 0.5s ease-in;
}
.VitaCaseStudy-img video,
.MojCaseStudy-box video {
  display: none;
}
.VitaCaseStudy-img:hover video,
.MojCaseStudy-box:hover video {
  position: absolute;
  top: 0%;
  left: 3.5%;
  display: block;
  z-index: 2;
  width: 96.5%;
  height: 100%;
  border-radius: 20px;
}
.MojCaseStudy-box-small img,
.VitaCaseStudy-img-small img {
  display: none;
}
@media screen and (max-width: 768px) {
  .Landing-Case-Study-img {
    display: none;
  }
  .MojCaseStudy-box-small,
  .VitaCaseStudy-img-small {
    width: 320px;
    height: 240px;
    margin: auto;
  }
  .MojCaseStudy-box-small img,
  .VitaCaseStudy-img-small img {
    width: 320px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 20px;
    box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.64);
    border: 0.5px solid black;
  }
}
/* media Presence ------------------------------------------------------------------------------------------ */
.Media-presence-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px auto 50px auto;
  flex-wrap: wrap;
  max-width: 850px;
}

.Media-presence-box img {
  width: 150px;
  margin-bottom: 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  transition: all 1s ease-out;
  cursor: pointer;
}

.Media-presence-box img:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 768px) {
  .Media-presence-box {
    max-width: 350px;
  }
  .Media-presence-box img {
    width: calc(300px / 2);
    height: 156px;
  }
}

/* Simple Approach --------------------------------------------------------------------------------------------- */
.SimpleAproach-section {
  max-width: 1200px;
  margin: auto;
}
.approach-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  color: #000;
  width: 1100px;
  margin: auto;
}
.SimpleAprroachInfo {
  margin-right: 20px;
}
.approach-box span {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

.approach-box h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}
.approach-box ul {
  margin: 0;
  padding: 0;
}
.approach-box li {
  list-style: none;
  margin-bottom: 10px;
}
.approach-box h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}
.approach-box p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: inherit;
}

.approach-box img {
  width: 450px;
  height: 450px;
  border-radius: 50%;
}
.approach-box .background2 {
  background-color: #000;
}
.approach-box .background3 {
  background-color: rgb(29, 143, 151);
}
.approach-box .background1 {
  background-color: aquamarine;
}
@media screen and (max-width: 768px) {
  .approach-box {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: auto;
  }
  .approach-box .SimpleAprroachImg {
    order: 1;
  }
  .approach-box .SimpleAprroachInfo h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .approach-box .SimpleAprroachInfo h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
  }
  .approach-box .SimpleAprroachInfo p {
    font-weight: 600;
    font-size: 12px;
    /* text-align: left; */
    line-height: 18px;
  }
  .approach-box .SimpleAprroachImg img {
    width: 300px;
    height: 313.36px;
    border-radius: 50%;
  }
  .approach-box .SimpleAprroachInfo {
    order: 2;
    margin: auto;
  }
}
.FAQ {
  font-family: Poppins, Arial, Helvetica, sans-serif;
}
.FAQ div {
  max-width: 800px;
  margin: auto auto 20px auto;
  border-radius: 20px;
  background-image: url(../Assets/Images/BackgroundImages/contactus.jpg);
  background-position: 100%;
  background-size: cover;
  padding: 10px;
}
.FAQ details {
  background: transparent;
  color: white;
  margin: 20px auto;
  transition: all 2s ease-in;
  border-radius: 20px;
}
.FAQ summary {
  font-size: large;
  text-align: left;
  padding: 10px;
  font-weight: 700;
  margin: 10px;
}
.FAQ details p {
  text-align: left;
  max-width: 750px;
  margin: 10px auto;
  font-weight: 600;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .FAQ div {
    width: 95%;
  }
}
