.CaseStudiesBanner {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
}

.CaseStudiesBanner h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  color: #ffffff;
}

.CaseStudiesBanner > p {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  max-width: 1000px;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Content cssss ----------------------------------------------------------------------------------- */
/* .CaseStudies-partner p {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 100px;
} */

.Case-Studies-box {
  width: 1254px;
  height: 528px;
  background: #7fcef3;
  border-radius: 40px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px 10px 90px;
}
.Case-Studies-box:nth-child(1) {
  margin-bottom: 40px;
}
.Case-Studies-box:nth-child(2) {
  background: #faf2cf;
  margin-bottom: 50px;
}
.Case-Studies-box h3 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  padding: 10px 20px;
}
.Case-Studies-box p {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  padding: 20px;
}
.Case-Studies-box img {
  width: 454px;
  height: 454px;
  border-radius: 40px;
}
@media screen and (max-width: 768px) {
  .CaseStudiesBanner h2 {
    font-size: 28px;
    line-height: 48px;
  }
  .CaseStudiesBanner p {
    font-size: 16px;
    line-height: 24px;
  }
  .CaseStudiesBtn {
    width: 150px;
    font-size: 16px;
    margin: 0;
  }
  .Case-Studies-box {
    flex-direction: column;
    width: auto;
    height: auto;
    text-align: center;
    padding: 10px;
  }
  .Case-Studies-box h3 {
    font-size: 24px;
    line-height: 44px;
    letter-spacing: -0.015em;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .Case-Studies-box p {
    font-size: 14px;
    line-height: 22px;
    padding: 0;
  }
  .Case-Studies-box img {
    width: 254px;
    height: 254px;
    border-radius: 40px;
  }
}
